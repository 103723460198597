<template>
  <div>
    <div class="table_sty">
      <div class="froms">
        <p class="describe">筛选</p>
        <div class="segmentation"></div>
        <div class="from_submit">
          <el-form :model="formLabelAlign" class="from">
            <el-form-item label="名称：" label-width="60px" style="width: 310px;">
              <el-input placeholder="请输入名称" size="mini" class="form-inp" v-model="formLabelAlign.name"></el-input>
            </el-form-item>

            <el-form-item label="时间选择：" label-width="90px">
              <div>
                <el-date-picker value-format="yyyy-MM-dd" v-model="formLabelAlign.bengin_time" type="date"
                  placeholder="开始日期" @change="DateChange()">
                </el-date-picker>
                至
                <el-date-picker value-format="yyyy-MM-dd" v-model="formLabelAlign.end_time" type="date" placeholder="结束日期"
                  :picker-options="pickerOptions">
                </el-date-picker>
              </div>
            </el-form-item>
            <!-- <el-form-item label="是否启用：">
              <el-select
                v-model="formLabelAlign.is_enable"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in enable_list"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item> -->
          </el-form>
          <div class="search_button">
            <div>
              <el-button @click="way_tableData(true)" type="primary" icon="el-icon-search">查询</el-button>
              <el-button @click="on_reset" icon="el-icon-refresh-left">重置</el-button>
            </div>
          </div>
        </div>
      </div>
      <div class="tab_sty">
        <div class="tab_top">
          <p>
            <span class="span1">一共{{ total }}个</span>
            <span class="span2">已选择 {{ more_List.length }} 个</span>
            <i @click="way_tableData" class="span_i el-icon-refresh">数据更新</i>
          </p>
          <div>
            <el-button type="primary" @click="on_addplus('/security/duty_redact')" icon="el-icon-plus">新建</el-button>
            <el-button @click="on_deleteList" icon="el-icon-close">删除</el-button>
            <!-- <el-button @click="on_export">导出</el-button> -->
          </div>
        </div>
        <div>
          <el-table highlight-current-row @current-change="way_singleselect" @selection-change="way_moreselect"
            :data="table_List" :header-cell-style="{ background: '#f8f8f9' }" style="flex: 1">
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column prop="name" label="名称"></el-table-column>
            <el-table-column prop="begin_time" label="开始时间"></el-table-column>
            <el-table-column prop="end_time" label="结束时间"></el-table-column>
            <!-- <el-table-column label="启用状态">
              <template slot-scope="scope">
                <el-switch
                  @change="on_is_enable($event, scope.row.id)"
                  v-model="scope.row.is_enable"
                  :active-value="1"
                  :inactive-value="0"
                >
                </el-switch>
              </template>
            </el-table-column> -->

            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-link class="list_sty" type="primary"
                  @click="on_addplus('/security/duty_redact', scope.row.id)">修改</el-link>
                <el-link class="list_sty" type="primary" @click="on_journal(scope.row.id)">日志</el-link>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="paging">
          <el-pagination @current-change="way_page" :page-size="page_size" layout="prev, pager, next, jumper"
            :total="total" :current-page="page">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import table_minin from "../../assets/minin/table_minin";

import Bepartment from "../../components/Bepartment";
import {
  dutyindex,
  dutydelete,
  dutystatusupdate,
} from "../../assets/request/api";
export default {
  name: "thought_education",
  mixins: [table_minin],
  components: { Bepartment },
  data() {
    return {
      is_extend: true,
      enable_list: [],
      formLabelAlign: {
        name: "",
        is_enable: "",
        bengin_time: "",
        end_time: "",
      },
    };
  },
  created() {
    this.url = dutyindex;
    this.is_enableUrl = dutystatusupdate;
    this.delete_Url = dutydelete;
    this.fromData = this.formLabelAlign;
    this.xl_attribute = "type_list";
    this.way_tableData();
  },
  methods: {
    way_extend(data) {
      this.enable_list = data.enable_list;
    },

    //统计周期结束日期禁用
    DateChange(type) {
      if (!type) {
        this.formLabelAlign.end_time = "";
      }
      let that = this;
      this.pickerOptions = {
        //日期禁用
        disabledDate(date) {
          return that.$tools.Time_todetermine(
            date,
            that.formLabelAlign.bengin_time
          );
        },
      };
    },

    on_journal(id) {
      this.$router.push({
        path: "/security/Scheduling_thelog",
        query: {
          id: id,
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.describe {
  display: inline-block;
  position: relative;
  padding: 4px;
  font-size: 16px;
  color: #4f7afd;
  font-weight: bold;
  padding-bottom: 10px;
}

.describe:before {
  content: "";
  position: absolute;
  bottom: -2px;
  width: 50%;
  height: 4px;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #4f7afd;
}

.from {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
}

.form-inp {
  width: 200px;
}

.paging {
  text-align: center;
  padding: 10px;
}

.tab_top {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;

  button {
    margin-right: 10px;
  }

  p {
    display: flex;
    align-items: center;
  }
}

.span1 {
  font-size: 14px;
  color: #000000;
}

.span2 {
  font-size: 14px;
  margin-left: 10px;
  color: #797979;
}

.span_i {
  font-size: 14px;
  color: #797979;
  margin-left: 10px;
  cursor: pointer;
}

.el-divider__text {
  color: #0080ff;
  font-weight: bold;
}

.segmentation {
  height: 1px;
  background-color: #f4f4f4;
  margin-bottom: 20px;
}

.list_sty {
  margin-right: 10px;
}

.search_button {
  display: flex;
  margin-bottom: 18px;
  flex-direction: column-reverse;
}
</style>
